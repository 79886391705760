export enum TierType {
  T1 = 'core.tier.T1',
  T2 = 'core.tier.T2',
  T3 = 'core.tier.T3',
  T4 = 'core.tier.T4',
  T5 = 'core.tier.T5',
}

export enum ModuleType {
  Action = 'core.module.action',
  Admin2 = 'core.module.admin2',
  Asset = 'core.module.asset',
  Attachment = 'core.module.attachment',
  BusinessIntelligence = 'core.module.bi',
  Bookmark = 'core.module.bookmark',
  Chemical = 'core.module.chemical',
  Contractor = 'core.module.contractor',
  CorporateRisk = 'core.module.crisk',
  Correspondence = 'core.module.correspondence',
  CorrespondenceRoll = 'core.module.correspondence.roll',
  CorrespondenceProse = 'core.module.correspondence.prose',
  Cusval = 'core.module.cusval',
  CusvalDefinition = 'core.module.cusval.definition',
  CusvalValues = 'core.module.cusval.values',
  DashboardBanner = 'core.module.dashboardbanner',
  Export = 'core.module.export',
  Filebox = 'core.module.filebox',
  HR = 'core.module.hr',
  Incident = 'core.module.incident',
  Inspection = 'core.module.inspection',
  Notification = 'core.module.notification',
  Noticeboard = 'core.module.noticeboard',
  NoticeBoardForm = 'core.module.noticeboard.form',
  NoticeboardBoard = 'core.module.noticeboard.board',
  NoticeBoardBlockList = 'core.module.noticeboard.blocklist',
  ReviewPlan = 'core.module.reviewplan',
  SafetyPlan = 'core.module.safetyplan',
  Search = 'core.module.atlassearch',
  Task = 'core.module.task',
  User = 'core.user',
  VersionDocument = 'core.module.vdoc',
  VDocVersion = 'core.module.vdoc.version',
  Visitor = 'core.module.visitor',
  VisitorSite = 'core.module.visitor.site',
  VisitorForm = 'core.module.visitor.form',
  VisitorVisit = 'core.module.visitor.visit',
  Xsi = 'core.module.xsi',
  Training = 'core.module.training',
  TierProfile = 'core.profile.tier',
  UserProfile = 'core.profile.user',
  PowerBI = 'core.module.bi',
  SuperSet = 'core.module.superset',
  l10n = 'core.module.l10n',
  Theme = 'core.module.theme',
  ImportManager = 'core.module.import',
  MorphUser = 'core.user',
  MobileAppAdmin = 'core.module.mobileappadmin',
  MobileAppAdminVersion = 'core.module.mobileappadmin.version',
  Freemium = 'core.module.freemium',
  Linkaccess = 'core.module.linkaccess',
  ResourceCenter = 'core.module.resourcecenter',
  ActionFileBox = 'core.module.filebox.action',
  AssetFileBox = 'core.module.filebox.asset',
  ChemicalFileBox = 'core.module.filebox.chemical',
  ContractorFileBox = 'core.module.filebox.contractor',
  CorporateRiskFileBox = 'core.module.filebox.crisk',
  HumanResourceFileBox = 'core.module.filebox.hr',
  IncidentFileBox = 'core.module.filebox.incident',
  ReviewPlanFileBox = 'core.module.filebox.reviewplan',
  VersionDocumentFileBox = 'core.module.filebox.vdoc',
  SocialFeed = 'core.module.social',
}

export enum LinkAccessType {
  ExchangeFetch = 'EXCHANGE_FETCH',
  ExchangeCusvalValuesBulkFetch = 'EXCHANGE_CUSVAL_VALUES_BULK_FETCH',
  ExchangeCusvalValuesCreate = 'EXCHANGE_CUSVAL_VALUES_CREATE',
  ExchangeEdit = 'EXCHANGE_EDIT',
  TaskSignOff = 'TASK_SIGNOFF',
  SetupVerify = 'SETUP_VERIFY',
  SetupComplete = 'SETUP_COMPLETE',
}

// modules that exists on config
export enum ModuleConfig {
  Action = 'action',
  Admin2 = 'admin2',
  Asset = 'asset',
  Chemical = 'chemical',
  Contractor = 'contractor',
  CorporateRisk = 'corporateRisk',
  Correspondence = 'correspondence',
  Export = 'export',
  HR = 'hr',
  Incident = 'incident',
  Inspection = 'inspection',
  Noticeboard = 'noticeboard',
  ReviewPlan = 'reviewplan',
  SafetyPlan = 'safetyplan',
  Training = 'training',
  VersionDocument = 'vdoc',
  Visitor = 'visitor',
  ImportManager = 'import',
  Task = 'task',
}

export interface OhsObject {
  type: TierType | ModuleType;
  _id: string;
}

export const ModuleDefaultTitle: Record<string, string> = {
  'core.module.safetyplan': 'Safety Plan',
  'core.module.inspection': 'Inspection',

  'core.module.vdoc': 'Document',
  'core.module.vdoc.version': 'Document',
  'core.module.crisk': 'Corporate Risk',
  'core.module.asset': 'Asset',

  'core.module.export': 'Export Centre',
  'core.module.correspondence': 'Communication',
  'core.module.noticeboard': 'Noticeboard',
  'core.module.hr': 'Human Resources',
  'core.module.contractor': 'Contractor',
  'core.module.action': 'Action',
  'core.module.task': 'Task',
  'core.module.filebox': 'File Box',
  'core.module.filebox.action': 'File Box',
  'core.module.filebox.asset': 'File Box',
  'core.module.filebox.chemical': 'File Box',
  'core.module.filebox.contractor': 'File Box',
  'core.module.filebox.crisk': 'File Box',
  'core.module.filebox.hr': 'File Box',
  'core.module.filebox.incident': 'File Box',
  'core.module.filebox.reviewplan': 'File Box',
  'core.module.filebox.vdoc': 'File Box',
  'core.module.chemical': 'Chemical',
  'core.module.incident': 'Incident',
  'core.module.reviewplan': 'Insurance',
  'core.module.noticeboard.board': 'Noticeboard',
  'core.module.training': 'Training',
  'core.module.import': 'Import Manager',
  'core.module.visitor': 'Visitor',
  'core.module.mobileappadmin': 'Mobile App Admin',
  'core.module.visitor.site': 'Site',
  'core.module.visitor.form': 'Visitor',
  'core.module.visitor.pin': 'Visitor',
  'core.module.l10n': 'Localization',
  'core.module.notification': 'Notification',
  'core.module.theme': 'Theme',
  'core.module.ni': 'Business Intelligence',
};

// config by core.module[name]
export const ConfigByModule = {
  [ModuleType.Action]: ModuleConfig.Action,
  [ModuleType.Admin2]: ModuleConfig.Admin2,
  [ModuleType.Asset]: ModuleConfig.Asset,
  [ModuleType.Chemical]: ModuleConfig.Chemical,
  [ModuleType.Contractor]: ModuleConfig.Contractor,
  [ModuleType.CorporateRisk]: ModuleConfig.CorporateRisk,
  [ModuleType.Correspondence]: ModuleConfig.Correspondence,
  [ModuleType.Export]: ModuleConfig.Export,
  [ModuleType.HR]: ModuleConfig.HR,
  [ModuleType.Incident]: ModuleConfig.Incident,
  [ModuleType.Inspection]: ModuleConfig.Inspection,
  [ModuleType.Noticeboard]: ModuleConfig.Noticeboard,
  [ModuleType.NoticeboardBoard]: ModuleConfig.Noticeboard,
  [ModuleType.ReviewPlan]: ModuleConfig.ReviewPlan,
  [ModuleType.SafetyPlan]: ModuleConfig.SafetyPlan,
  [ModuleType.VersionDocument]: ModuleConfig.VersionDocument,
  [ModuleType.Visitor]: ModuleConfig.Visitor,
  [ModuleType.ImportManager]: ModuleConfig.ImportManager,
  [ModuleType.Training]: ModuleConfig.Training,
};

export interface OhsModule extends OhsObject {
  type: ModuleType;
}

export class OhsModuleCreateBy implements OhsObject {
  type: ModuleType.User;

  _id: string;

  name: string;

  email: string;

  constructor() {
    this.type = ModuleType.User;
    this._id = '';
    this.name = '';
    this.email = '';
  }
}

export enum OhsModuleDetailType {
  Detail = 'detail',
  Evidence = 'evidence',
}
